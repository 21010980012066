import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import { Link } from 'react-router-dom';

import PageHeader from '../components/PageHeader';

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const GuideProfile = (props) => {
  const { guideId } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`/api/v1/guides?id=${guideId}&with_events=true`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [guideId]);

  const guide = data;

  if (guide === undefined) return;

  return (
    <>
      <PageHeader
        button={
          <Link className="btn btn-green" target="blank" to={guide.website_url}>
            BOEK NU
          </Link>
        }
        imageClass="agenda"
      />
      <section className="grey-section">
        <div className="content content-column">
          <h3>{guide.practice}</h3>
          <p className="margin-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed urna ex, aliquam eget nibh ac, pretium
            sollicitudin velit. Pellentesque efficitur dui ornare, tincidunt lectus sit amet, posuere tortor. Maecenas
            bibendum, velit quis elementum eleifend, augue turpis tempus dui, vitae tincidunt elit tortor in quam.
            Quisque elementum,{' '}
          </p>
          <Link className="btn btn-green" to="/boek-een-bosbad">
            LEES MEER
          </Link>
        </div>
      </section>
      {guide.events && (
        <Table className="agenda-table">
          <thead>
            <tr>
              <th>Praktijk</th>
              <th>Startdatum</th>
              <th>Locatie</th>
            </tr>
          </thead>
          <tbody>
            {guide.events.map((event) => (
              <tr key={event.id} className="link-row">
                <td>{guide.practice}</td>
                <td>{new Date(event.start_date).toLocaleString('nl-NL', options)}</td>
                <td>
                  <a href={`/gids/${event.guide_id}`}>{guide.city}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default GuideProfile;
